* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Montserrat', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

main {
  width: 100%;
  margin-top: 64px;
  padding-left: 8px;
  padding-right: 8px;
}

@media (max-width: 600px) {
  main {
    padding: 0;
  }
}

.swal2-container {
  z-index: 100000000000 !important;
}

/* nunito-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/nunito/nunito-v25-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('/fonts/nunito/nunito-v25-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/nunito/nunito-v25-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('/fonts/nunito/nunito-v25-latin-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/nunito/nunito-v25-latin-600.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('/fonts/nunito/nunito-v25-latin-600.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/nunito/nunito-v25-latin-700.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('/fonts/nunito/nunito-v25-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* dm-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/dm_sans/dm-sans-v11-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('/fonts/dm_sans/dm-sans-v11-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* dm-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/dm_sans/dm-sans-v11-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('/fonts/dm_sans/dm-sans-v11-latin-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* dm-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/dm_sans/dm-sans-v11-latin-700.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url('/fonts/dm_sans/dm-sans-v11-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
